<template>
  <div class="pages">
    <h3>加入我们</h3>
    <div class="conent">
    <img :src="img" alt="">
    
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      img:'https://www.zhongou.org.cn/e/data/ecmseditor/kindeditor/attached/image/201909/20190918112934_74433.jpg',
    }
  }
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    margin: 20px 57px 0 60px;
    padding-bottom: 73px;
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 41px;
      color: #333333;
      margin-bottom: 30px;
      margin-top: 50px;
    }
    .redfont {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #e35757;
    }
    p {
      width: 1113px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #666666;
      margin-bottom: 20px;
    }
  }
}
</style>