<template>
  <div class="pages">
    <h3>课程通知</h3>
    <div class="conent">
      <h2>部分医院的课后分享</h2>
      <div class="box">
        <div v-for="(item,index) in list" :key="index" class="list">
          <p>医院：{{item.hospital}}</p>
          <p>标题：{{item.title}}</p>
          <p>
            链接：
            <a
              :href=" item.link"
            >{{item.link}}</a>
          </p>
        </div>
        <div class="list">
          <p>医院：长治市人民医院</p>
          <p>标题：市医院通过中欧医管云课堂直播形式进行《病历书写规范》培训</p>
          <p>
            链接：
            <a
              href=" http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w"
            >http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {voclist} from '@/api/news'
export default {
  data(){
    return{
      list:[]
    }
  },
  created(){
    voclist().then(res=>{
     if(res.code === 1){
       this.list = res.data.data
     }
    })
  },
    mounted(){
     var a= document.getElementsByClassName('el-aside')[0];
      if(this.$route.name =='trends'){
     
    // console.log(a)
       if(this.$route.name =='trends'){
      a.style.display = 'none'
    }else{
      a.style.display = 'block'
      
    }
    }
  },
};
</script>
<style scoped lang='less'>
.pages {
  // width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 41px;
      color: #333333;
      margin: 30px 0 0 52px;
    }
    .box {
      margin: 30px 87px 0 25px;
    //   display: flex;
      padding-bottom: 45px;
      .list {
        width: 1105px;
        height: 193px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 3px 3px 10px rgba(79, 141, 254, 0.16);
        opacity: 1;
        border-radius: 6px;
        p {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          //   line-height: 40px;
          color: #666666;
          margin: 30px 0 0 60px;
        }
      }
    }
  }
}
</style>