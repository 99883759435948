import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '../router'

import errorCode from '@/utils/errorCode'
import { tansParams } from "@/utils/ruoyi";
let token = localStorage.getItem('token')
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
var flag = true
var flags = true
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://pcapi.yiketang.vip',
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token

  if (token) {
    // config.headers['Authorization'] ='token' + '=' +token// 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  console.log(config)


  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 1;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 99) {
    if (msg === '您的账号在另一设备进行登录，请重新登录' && flags == true) {
      flags = false
      MessageBox.confirm('您的账号在另一设备进行登录，请重新登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '退出',
        type: 'warning'
      }
      ).then(() => {
        sessionStorage.removeItem('data')
        router.push({ path: '/login' })
        // store.dispatch('LogOut').then(() => {
        // router.push({ path: '/login' })
        return
        // })
      }).catch(() => {
        sessionStorage.removeItem('data')
        router.push({ path: '/login' })
        return
      })
      setTimeout(() => {
        flags = true
      }, 300000);
    }else{
      if (flag && msg != '您的账号在另一设备进行登录，请重新登录') {
        flag = false
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
        ).then(() => {
          router.push({ path: '/login' })
          // store.dispatch('LogOut').then(() => {
          // router.push({ path: '/login' })
          
          // })
        }).catch(() => {
          // router.push({ path: '/login' })
          return
        })
        setTimeout(() => {
          flag = true
        }, 3000);
      }
    }
  
  } else if (code === 99) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 1) {
    // Notification.error({
    //   title: msg
    // })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download(url, params, filename) {
  return service.post(url, params, {
    transformRequest: [(params) => {
      return tansParams(params)
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob'
  }).then((data) => {
    const content = data
    const blob = new Blob([content])
    if ('download' in document.createElement('a')) {
      const elink = document.createElement('a')
      elink.download = filename
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    } else {
      navigator.msSaveBlob(blob, filename)
    }
  }).catch((r) => {
  })
}

export default service
