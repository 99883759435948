<template>
  <div class="pages">
    <h3>关于我们</h3>
    <div class="conent">
        <h1 style="font-size:32px;text-align:center;margin-bottom:10px">关于我们</h1>
      <!-- <h2>发布时间：2016-03-21 16:26:44 浏览次数：124636 次 【字体：小 大】</h2> -->
      <p>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中欧国际医院管理中心成立于2005年，涉足医院培训、咨询、境外医院考察、出版、医生多点执业、医院科室共建、医院托管、医疗投融资等领域，拥有北京中欧国际医院管理技术研究院、北京中欧国际医院管理咨询有限公司、中欧医生集团（深圳）有限公司（前海股权交易中心挂牌代码367718）、北京欧中医盟科技有限公司、北京欧中医疗投资管理有限公司、北京医课堂科技有限公司、北京陆爱思医疗管理有限公司、北京华清医疗管理有限公司等多家实体机构，是全球外科医生基金会、台湾北医医务管理协会北京合作机构、是中国生命关怀协会医院人文建设专业委员会依托单位，中国医院协会企业分会学科建设合作单位。旨在提升医院管理人员的职业化水平，优化就医流程，改善服务质量，提升医院学科建设水平，促进中国医疗事业发展。中心拥有百余位医院实战管理专家，近千名高级职称加盟多点执业医生，全国各省市知名三甲医院管理培训基地单位百余家，固定会员单位6000余家，每年深度医院管理咨询近百家，线上中欧医管云课堂（www.zhongou.org.cn）及线下培训50万余人次。经过15年的发展，中欧医管已经成为医院管理领域知名品牌。
      </p>
    </div>
    <div class="contact">
      <el-row class="bj" :gutter="60">
        <el-col :span="8">业务免费咨询电话：400-600-9266</el-col>
        <el-col :span="8">客户服务中心电话：010-62715587</el-col>
        <el-col :span="8">传真：010－62717228</el-col>
        <el-col :span="8">邮件：zhongouguoji@126.com</el-col>
        <el-col :span="12">地址：北京市海淀区西三旗金燕龙大厦709室</el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {news} from '@/api/news'
export default {
  data(){
    return {
      data:{},
    }
  },
  created(){
    news({type:2}).then(res=>{
      this.data = res.data.data[1]
    })
  },
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    min-height: 240px;
    margin: 30px 57px 0 60px;
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 41px;
      color: #333333;
      margin-bottom: 30px;
    }
    p {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #666666;
    }
  }
  .contact {
    padding: 60px;
    .bj {
      background-color: #f9f9f9;
      div {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 34px;
        color: #717171;
        margin: 30px 0;
      }
    }
  }
}
</style>