<template>
  <div class="pages">
    <h3>用户之声</h3>
    <div class="conent">
      <!-- <h2>部分医院的课后分享</h2> -->
      <el-card v-for="(item,index) in data" :key="index" shadow="never" class="box-card">
        <el-descriptions column="1">
          <el-descriptions-item label="医院">{{item.hospital}}</el-descriptions-item>
          <el-descriptions-item label="标题">{{item.title}}</el-descriptions-item>
          <el-descriptions-item label="链接">
            <a
              :href="item.link"
            >{{item.link}}</a>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <!-- <el-card shadow="never" class="box-card">
        <el-descriptions column="1">
          <el-descriptions-item label="医院">长治市人民医院</el-descriptions-item>
          <el-descriptions-item label="标题">市医院通过中欧医管云课堂直播形式进行《病历书写规范》培训</el-descriptions-item>
          <el-descriptions-item label="链接">
            <a
              href="http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w"
            >http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w</a>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card shadow="never" class="box-card">
        <el-descriptions column="1">
          <el-descriptions-item label="医院">长治市人民医院</el-descriptions-item>
          <el-descriptions-item label="标题">市医院通过中欧医管云课堂直播形式进行《病历书写规范》培训</el-descriptions-item>
          <el-descriptions-item label="链接">
            <a
              href="http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w"
            >http://mp.weixin.qq.com/s/J08U5I8XWiQVHKRe6yvo9w</a>
          </el-descriptions-item>
        </el-descriptions>
      </el-card> -->
    </div>
  </div>
</template>
<script>
import {voclist} from '@/api/news'
export default {
  data(){
    return{
data:[],
    }
  },
  created(){
    voclist().then(res=>{
     this.data = res.data.data
    })
  },
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    margin: 70px 57px 0 60px;
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 41px;
      color: #333333;
      margin-bottom: 30px;
    }
    .box-card{
        margin-bottom: 30px;
    }
  }
}
::v-deep .el-descriptions__table {
  span {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    color: #666666;
  }
}
</style>