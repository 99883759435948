<template>
  <div class="pages">
    <h3>服务项目</h3>
    <div class="conent">
      <div v-show="show === 1">
            <h2 class="title_cont">{{lists.title}}</h2>
             <div class="font">
          
          <div class="title_time">
            <p>发布时间：{{lists.create_time}}</p>
          </div>
          <p v-html="lists.content">
            
          </p>
          <hr/>
          <div class="back" @click="show=0">返回列表</div>
        </div>
      </div>
      <ul v-show="show === 0">
        <li  v-for="(item,index) in data" :key="index" @click="list(index,item.id)">
          {{item.title}}
          <span :class="index != 0?'time':''">{{(moment(item.create_time).format('YYYY-MM-DD'))}}</span>
        </li>
        <!-- <li @click="list(1)">人力资源
           <span class="time">2018-11-09</span>
        </li>
        <li @click="list(2)">  绩效咨询
          <span class="time">2018-11-09</span></li>
        <li @click="list(3)">  6S精益管理
          <span class="times">2018-11-09</span></li>
        <li @click="list(4)">   患者满意
          <span class="time">2018-11-09</span></li>
           <li @click="list(5)">   科室建设
          <span class="time">2018-11-09</span></li>
           <li @click="list(6)">  直播课堂
          <span class="time">2018-11-09</span></li> -->
         
      </ul>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {news,getDetail} from '@/api/news'
export default {
  data(){
    return{
      show:0,
      data:[],
      lists:{},
          }
  },
  created(){
       news({type:2}).then(res=>{
      this.data = res.data.data
    })
  },
  methods:{
  moment,
    list(index,id){
      getDetail({id}).then(res=>{
        console.log(res)
        if(res.code == 1){
          this.show = 1
 this.lists = res.data
        }
       
      })
      // if(index == 0){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=792','_blank');  
      // }
      //   if(index == 1){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=598','_blank');  
      // }
      //  if(index == 2){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=593','_blank');  
      // }
      //   if(index == 3){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=592','_blank');  
      // }
      // if(index == 4){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=590','_blank');  
      // }
      //  if(index == 5){
      //   window.open('https://www.zhongou.org.cn/e/action/ShowInfo.php?classid=4&id=589','_blank');  
      // }
    },
  },
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;

  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    min-height: 240px;
    padding: 60px 80px 0 80px;
    li {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 40px;
      color: #525252;
      list-style-type: disc;
      padding-bottom: 50px;
      cursor: pointer;
      .time{
        margin-left: 871px;
      }
        .times{
        margin-left: 847px;
      }
      span{
        margin-left: 697px;
      }
    }
  }
}
.title_cont{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.times{
  width: 1000px;
  // height: 0px;
  background-color: #666;
}
 .font{
      width: 1026px;
      margin: 20px auto;
      .title_time{
        background-color: #F6F5FA;
      }
  p{
    font-size: 15px;
font-family: Source Han Sans CN;
font-weight: 400;
line-height: 26px;
color: #666666;
text-align: center;
margin-bottom: 20px;
  }
 }
 .back{
   font-size: 20px;
   cursor: pointer;
   text-align: right;
 }
</style>