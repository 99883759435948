<template>
  <div class="pages">
    <h3>培训基地</h3>
    <div class="conent">
      <h2>中欧医管云课堂</h2>
      <div class="font">
        <p>业务免费咨询电话：400-600-9266</p>
        <p>客户服务中心电话：010-62715587</p>
        <p>传真：010－62717228</p>
        <p>邮件：zhongouguoji@126.com</p>
        <p>地址：北京市海淀区西三旗金燕龙大厦709室</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  height: 638px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    margin: 70px 57px 0 60px;
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 41px;
      color: #333333;
    }
    .font {
      width: 409px;
      height: 220px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 50px;
      color: #666666;
    }
  }
}
</style>