<template>
  <div id="app">
  <!-- <keep-alive> -->
    <router-view/>
  <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
   created(){
     this.bodyScale()
   },
   methods:{
      bodyScale() {
        var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可视区域宽度
        console.log(devicewidth)
        var scale = devicewidth / 1920; // 除以设计稿的尺寸
        document.body.style.zoom = scale; // 放大缩小相应倍数
      }
   },

}
</script>
<style lang="less">

</style>
