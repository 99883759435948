<template>
  <div class="pages">
    <h3>培训基地</h3>
    <div>
      <h2>培训基地</h2>
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index) in img" :key="index">
          <img style="width:100%;height:100%" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      img:['http://oss2.zhongou.org.cn/web/p/k/201610/20161008151449_37566.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008151653_23960.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008152001_85990.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008152027_58619.png']
    }
  }
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  height: 638px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  h2 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 51px;
    color: #333333;
    // margin-top: 30px;
    // padding-top: 100px;
    text-align: center;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
::v-deep .el-carousel__container {
  margin: 50px auto 0px auto;
  width: 800px;
  height: 433px;
}
::v-deep .el-carousel__arrow{
  width: 75px;
  height: 75px;
  font-size: 50px;
}
</style>