<template>
  <div class="pages">
    <h3>课程通知</h3>
    <div class="conent">
      <div v-for="(item,index) in data" :key="index" class="box" @click="btnurl(item.id,item.create_time)">
        <div class="moment">
          <h4>{{moment(item.create_time).format('DD')}}</h4>
          <h5>{{moment(item.create_time).format('YYYY-MM')}}</h5>
        </div>
        <div class="font">
          <h2>{{item.title}}</h2>
          <p v-html="item.content">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { news } from "@/api/news";
import moment from 'moment'
export default {
  data(){
    return{
      data:[],
    }
  },
  created(){
    news({type:1}).then(res=>{
      console.log(res)
      this.data = res.data.data
    })
  },
    mounted(){
     var a= document.getElementsByClassName('el-aside')[0];
      if(this.$route.name =='trends'){
     
    // console.log(a)
       if(this.$route.name =='trends'){
      a.style.display = 'none'
    }else{
      a.style.display = 'block'
      
    }
    }
  },
  methods:{
    moment,
    btnurl(id,time){
     this.$router.push({path:'/trends',query:{id,show:1}})
    },
  },
};
</script>
<style scoped lang='less'>
.pages {
  // width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
    .box {
        margin: 30px 87px 0 25px;
      display: flex;
      padding-bottom: 45px;
      cursor: pointer;
      .moment {
        width: 80px;
        background-color: #f9f9f9;
        margin-right: 20px;
        height: 100px;
        h4 {
        //   width: 52px;
        //   height: 43px;
          font-size: 43px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 49px;
          color: #999999;
          text-align: center;
          margin-top: 18px;
        }
        h5 {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        //   line-height: 47px;
          color: #999999;
          text-align: center;
        }
      }
      .font {
        flex: 1;
        width: 1017px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.16);
        h2 {
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 40px;
          color: #525252;
          margin-left: 20px;
        }
        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #666666;
          margin: 20px 66px 20px 20px;
        }
      }
    }
  }
}
</style>